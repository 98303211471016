<template>
  <v-subheader class="label-left">
    {{ text }}<template v-if="required">*</template></v-subheader
  >
</template>

<script>
export default {
  props: {
    text: String,
    required: Boolean,
  },
};
</script>

